
// @ is an alias to /src
import FlatButton from "@/components/atomics/FlatButton.vue";
import FileTabs from "@/components/atomics/file-tabs/FileTabs.vue";
import FileTab from "@/components/atomics/file-tabs/FileTab.vue";
import { defineComponent, onMounted, reactive, ref, Ref, toRefs } from "vue";
import OnboardingRepository from "@/lib/https/onboardingRepository";
import { RepositoryFactory } from "@/lib/https";
import { useRoute, useRouter } from "vue-router";
import { storage } from "@/lib/storage";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Terms",
  components: {
    FlatButton,
    FileTabs,
    FileTab,
  },
  setup(props) {
    const termsAgreed = ref(false);
    const receiveNotification = ref(false);
    const { t } = useI18n();

    const { agreeTerm } = agreeTerms();
    const { submit } = formHandler(receiveNotification, agreeTerm);
    const state = reactive({
      list: [
        {
          name: t("onboarding.termTitleTag"),
          content: "/term-converted.html",
        },
        {
          name: t("onboarding.termPrivacy"),
          content: "/privacy-converted-filter.htm",
        },
      ],
    });

    const route = useRoute();
    const router = useRouter();

    const goNext = () => {
      submit();

      if (route.query.isCompanyCreating === "true") {
        const localStorage = storage.getLocalStorage();
        localStorage.add("isCompanyCreating", true);
        router.push({
          name: "Organization",
          query: { 
            partner: route.query.partner
          }
        });
      } else
        router.push({
          name: "Profile",
          query: {
            isCompanyCreating: "false"
          }
        });
    };

    const onLoaded = () => {
      const frame = document.querySelector(".frame-content") as any;
      frame.contentDocument.body.style.overflowX = "hidden";
    };

    return {
      termsAgreed,
      receiveNotification,
      agreeTerm,
      submit,
      ...toRefs(state),
      goNext,
      onLoaded,
    };
  },
});

const formHandler = (
  isReceiveNotification: Ref<boolean>,
  onSubmit: CallableFunction
) => {
  const submit = () => {
    const data = new FormData();
    data.append("termsAgreed", "true");
    data.append(
      "receiveNotification",
      isReceiveNotification.value ? "true" : "false"
    );

    onSubmit(data);
  };

  return {
    submit,
  };
};

// agreeTerm
const agreeTerms = () => {
  const { agreeTerm } =
    RepositoryFactory.getRepository<OnboardingRepository>(OnboardingRepository);

  return {
    agreeTerm,
  };
};
