import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d2b6478"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "term" }
const _hoisted_2 = { class: "term-container" }
const _hoisted_3 = { class: "tagline" }
const _hoisted_4 = { class: "title mt-1" }
const _hoisted_5 = { class: "term-content" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "term-checkbox mt-2" }
const _hoisted_8 = { class: "checkbox-line" }
const _hoisted_9 = {
  for: "termsAgreed",
  class: "message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_tab = _resolveComponent("file-tab")!
  const _component_file_tabs = _resolveComponent("file-tabs")!
  const _component_FlatButton = _resolveComponent("FlatButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("onboarding.termTaglineText")), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("onboarding.termTitleText")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_file_tabs, {
          "is-border": false,
          "is-terms": true
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (file, index) => {
              return (_openBlock(), _createBlock(_component_file_tab, {
                key: index,
                name: file.name
              }, {
                default: _withCtx(() => [
                  _createElementVNode("iframe", {
                    src: file.content,
                    class: "frame-content",
                    onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoaded && _ctx.onLoaded(...args)))
                  }, "\r\n            ", 40, _hoisted_6)
                ]),
                _: 2
              }, 1032, ["name"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("input", {
            class: "confirm-box",
            type: "checkbox",
            name: "termsAgreed",
            value: "termsAgreed",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.termsAgreed) = $event)),
            id: "termsAgreed"
          }, null, 512), [
            [_vModelCheckbox, _ctx.termsAgreed]
          ]),
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("onboarding.termFirstCheckbox")), 1)
        ])
      ]),
      _createVNode(_component_FlatButton, {
        text: _ctx.$t('onboarding.termButtonText'),
        enabled: _ctx.termsAgreed,
        class: "term-button",
        onOnClick: _ctx.goNext
      }, null, 8, ["text", "enabled", "onOnClick"])
    ])
  ]))
}